<template>
  <div class="order_detail">
    <div class="basic_info">
      <div class="title">订单基本信息</div>
      <div class="info_box">
        <div class="item">订单编号：<span class="value">{{goodsOrder.orderNumber}}</span></div>
        <div class="item">下单时间：<span class="value">{{goodsOrder.createTime}}</span></div>
        <div class="item">服务名称：<span class="value">{{goodsOrder.goodsName}}</span></div>
        <div class="item">服务次数：<span class="value">{{goodsOrder.useNumber}}/{{goodsOrder.number}}</span></div>
        <div class="item">订单套餐：<span class="value">{{goodsOrder.specName}}{{goodsOrder.specName}}{{goodsOrder.duration? goodsOrder.duration+'小时':''}}</span></div>
        <div class="item">服务时间：<span class="value">{{goodsOrder.paySuccessTime}}</span></div>
        <div class="item">订单取消原因：<span class="value">{{goodsOrder.remark||''}}</span></div>
      </div>
    </div>

    <div class="user_info">
      <div class="title">下单用户信息</div>
      <div class="info_box">
        <div class="item">用户姓名：<span class="value">{{serviceOrder.userName}}</span></div>
        <div class="item">手机号码：<span class="value">{{serviceOrder.telNumber}}</span></div>
        <div class="item">用户来源：<span class="value">{{'未知'}}</span></div>
        <div class="item">服务地址：<span class="value">{{serviceOrder.provinceName}}{{serviceOrder.cityName}}{{serviceOrder.countyName}}{{serviceOrder.detailInfo}}</span></div>
      </div>
    </div>

    <div class="staff_info">
      <div class="title">服务人员信息</div>
      <div class="info_box">
        <div class="item">服务人员姓名：<span class="value">{{hmServiceStaffVo.name}}</span></div>
        <div class="item">手机号码：<span class="value">{{hmServiceStaffVo.phone}}</span></div>
        <div class="item">地址：<span class="value">
          {{hmServiceStaffVo.province}}{{hmServiceStaffVo.city}}{{hmServiceStaffVo.county}}{{hmServiceStaffVo.address}}
        </span></div>
      </div>
    </div>

    <div class="user_evaluation">
      <div class="title flex jc-between">
        <div>用户评价</div>
        <div>匿名</div>
      </div>
      <div class="rate text-14 py-12  flex jc-between ai-center">
        <div class="flex ai-center">上门速度：<el-rate v-model="evaluate.speed" disabled></el-rate></div>
        <div class="flex ai-center">服务态度：<el-rate v-model="evaluate.manner" disabled></el-rate></div>
        <div class="flex ai-center">服务质量：<el-rate v-model="evaluate.quality" disabled></el-rate></div>
        <div class="flex ai-center">总体评价：<el-rate v-model="evaluate.integratedService" disabled></el-rate></div>
      </div>
      <div class="evaluate">
        {{evaluate.evaluate||'用户暂未填写文字评价！'}}
      </div>
      <div class="image_info" v-if="fileList.length>0">
        <el-image
            class="image"
            v-for="(src,i) in fileList" :key="i"
            :src="src"
            :preview-src-list="fileList">
        </el-image>
      </div>
      <div class="reply" v-if="evaluate?.children?.length>0">
        <span>回复：</span>{{evaluate.children[0].evaluate}}
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      detail:{},
      hmServiceStaffVo:{},
      serviceOrder:{},
      goodsOrder:{},
      evaluate:{},
      fileList:[],
      status:['待支付','待服务','已取消','待评价','已完成','服务中']
    }
  },
  created() {
    if(this.$route.query.id){
      this.getOrderDetail(this.$route.query.id)
    }
  },
  methods: {
    getOrderDetail(id) {
      this.$curl.get('/hm/order/evaluateDetail/'+id).then(res => {
        this.detail = res.data
        this.goodsOrder = this.detail.goodsOrder
        this.serviceOrder = this.detail.serviceOrder
        this.hmServiceStaffVo = this.detail.hmServiceStaffVo
        this.evaluate = this.detail.evaluate
        if(this.evaluate.imgUrl){
          this.fileList = this.evaluate.imgUrl.split(',')
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.order_detail{
  background: #FFFFFF;
  height: 100%;
  width: 100%;
  margin-top: 15px;
  overflow: auto;
}

.basic_info,
.user_info,
.staff_info,
.user_evaluation{
  margin: 0 42px;
  padding: 16px 0;
  .title{
    font-weight: bold;
    padding-bottom: 2px;
  }

}
.basic_info,
.user_info,
.staff_info{
  border-bottom: 1px solid #E8E8E8;
  .info_box{
    display: flex;
    flex-wrap: wrap;
    .item{
      width: 33.33%;
      padding-top: 10px;
      font-size: 14px;
      // 换行
      white-space: pre-wrap;
      .value{
        color: #666666;
      }
    }
  }
}

.image_info{
  padding-top: 20px;
  .image{
    width: 140px;
    height: 140px;
    border-radius: 10px;
    margin-right: 10px;
  }
}
.user_evaluation{
  .title {
    display: flex;
    justify-content: space-between;
  }
  .rate{
    font-size:14px;
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .evaluate{
    white-space: initial;
  }
  .reply{
    font-size: 14px;
    color: #999999;
    padding: 10px;
    background: #F5F6F8;
    border-radius: 8px;
    margin: 20px 0;
    span{
      color: #3D3D3D;
    }
  }
}


::v-deep(.el-table td.el-table__cell){
  border-bottom: 1px solid #ebeef5;
}
::v-deep(.el-rate__icon){
  font-size: 26px !important;
}
::v-deep(.el-rate){
  height: 100% !important;
}
</style>